<template>
  <div class="free_page content">
    <img src="@/assets/images/luyangmao/free.jpg" alt="">
    <!-- <img src="@/assets/images/luyangmao/free1.jpg" alt="">
    <img src="@/assets/images/luyangmao/free2.jpg" alt="">
    <img src="@/assets/images/luyangmao/free3.jpg" alt=""> -->
    <!-- <div class="content"> -->
      <!-- <img src="@/assets/images/luyangmao/free4.jpg" alt="">
      <img src="@/assets/images/luyangmao/free5.jpg" alt="">
      <img src="@/assets/images/luyangmao/free6.jpg" alt="">
      <img src="@/assets/images/luyangmao/free7.jpg" alt="" class="tips"> -->
      <a class="btn1" target="_blank"></a>
      <a href="https://www.xiaohongshu.com/user/profile/5c03b3260000000007012198?xhsshare=CopyLink&appuid=59cdf4f96eea887776cffb95&apptime=1619061238" class="btn2" target="_blank"></a>
      <a href="https://weibo.com/2575075652/manage" class="btn3" target="_blank"></a>
    <!-- </div> -->
  </div>
</template>

<script>


export default {
  name: 'Free',
  components: {
  }
}
</script>
<style scoped lang='scss'>
.free_page{
  background: #e6e6e6;
  &.content{
    // width: 1450px;
    margin: 0 auto;
    // margin-top: -50px;
    position: relative;
    .btn1{
      position: absolute;
      top: 12.08333rem;
      width: 1.35417rem;
      height: 1.35417rem;
      left: 4.08542rem;
    }
    .btn2{
      position: absolute;
      top: 15.625rem;
      width: 1.35417rem;
      height: 1.35417rem;
      left: 4.08542rem;
    }
    .btn3{
      position: absolute;
      top: 19.0625rem;
      width: 1.35417rem;
      height: 1.35417rem;
      left: 4.08542rem;
    }
  }
  .tips{
    margin-top: 40px;
    margin-bottom: 70px;
  }
}
</style>
